import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DoneIcon from '@material-ui/icons/Done';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import classnames from 'classnames';
import _ from 'lodash';

import { colors } from 'theme/palette';
import {
  AssignmentStatusOptions,
  AssignmentTaskValueTypeValueRange,
  AssignmentTaskValueTypes
} from 'utils/constants';
import { usePrevious } from 'utils/hooks';
import { getRubricAFValueByRawValue } from 'utils/trackers';

import Button from 'components/Button';
import DebouncedField from 'components/DebouncedField';
import InputField from 'components/InputField';
import Switch from 'components/Switch';
import Typography from 'components/Typography';

import { updateAssignmentScore } from '../../sdk';
import Icons from './Icons';
import styles from './styles.module.scss';

const Section = ({ children, className }) => (
  <Box className={classnames(styles.section, className)}>{children}</Box>
);

const IconsList = ({ children }) => (
  <Grid container alignItems="center" className={styles.iconsList}>
    {children &&
      children.map((item, index) => (
        <Grid item key={index}>
          {item}
        </Grid>
      ))}
  </Grid>
);

const TooltipIconButton = ({ tooltipText, disabled, children, ...props }) => {
  // Material UI Tooltip cannot work with disabed buttons
  if (disabled) {
    return (
      <IconButton disabled={disabled} {...props}>
        {children}
      </IconButton>
    );
  }

  return (
    <Tooltip
      arrow
      title={<Typography variant="B-Text-2">{tooltipText}</Typography>}
    >
      <IconButton disabled={disabled} {...props}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
const TooltipToggleButton = ({ tooltipText, disabled, children, ...props }) => {
  // Material UI Tooltip cannot work with disabed buttons
  if (disabled) {
    return (
      <ToggleButton disabled={disabled} {...props}>
        {children}
      </ToggleButton>
    );
  }

  return (
    <Tooltip
      arrow
      title={<Typography variant="B-Text-2">{tooltipText}</Typography>}
    >
      <ToggleButton disabled={disabled} {...props}>
        {children}
      </ToggleButton>
    </Tooltip>
  );
};

// The score value is kept as a number for some reason
const numberToBool = (number) => {
  return number === 0 ? false : true;
};
const boolToNumber = (bool) => {
  return bool ? 1 : 0;
};

const useCachedCriteria = (activeTask) => {
  const [currentTaskId, setCurrentTaskId] = useState();
  const [criteria, setCriteria] = useState();

  // Resetting task
  useEffect(() => {
    if (activeTask.id !== currentTaskId) {
      setCurrentTaskId(activeTask.id);
      setCriteria(); // Reset criteria;
    }
  }, [activeTask, currentTaskId]);

  useEffect(() => {
    if (activeTask.criteria && _.isNil(criteria)) {
      setCriteria(activeTask.criteria);
    }
  }, [activeTask.criteria, criteria]);

  return [criteria, setCriteria];
};

const ExpandedRightMenu = ({
  activeTask,
  activeWork,
  rotateImage,
  cropButton,
  filterButton,
  drawButton,
  textButton,
  stickersButton,
  audioButton,
  onTaskUpdate,
  onClickAssignmentStatus,
  zoom,
  isExpanded,
  hideMenu,
  undo,
  isLoading
}) => {
  const [advanceOnStatusChange, setAdvanceOnStatusChange] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [criteria, setCriteria] = useCachedCriteria(activeTask);

  const activeWorkId = activeWork && activeWork.id;
  const prevWorkId = usePrevious(activeWorkId);

  useEffect(() => {
    // Deactivate all tools when the image is changed
    // TODO: Revisit this! I don't think this effect has to be here
    if (prevWorkId && activeWorkId !== prevWorkId) {
      filterButton.remove();
      filterButton.turnOff();
      cropButton.turnOff();
      drawButton.turnOff();
      audioButton.turnOff();
      stickersButton.turnOff();
      undo.clear();
      // Fixes a race condition
      setTimeout(zoom.zoomToNormal, 0);
    }
  }, [
    activeWorkId,
    prevWorkId,
    cropButton,
    filterButton,
    drawButton,
    audioButton,
    stickersButton,
    zoom,
    undo
  ]);

  const submit = (promise) => {
    setSubmitting(true);
    promise
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const handleCriteriaClick = (newCriteria) => {
    // This check is for legacy reasons.
    // Old value_types can be "rubric"/"check_grade"/"checkbox". We want to submit only if it's "checkbox"
    if (newCriteria.value_type === AssignmentTaskValueTypes.CHECKBOX) {
      const newValue = !numberToBool(newCriteria.value);
      // `newCriteria` type objects share the same id with `Task` type objects.
      const newCriteriaList = criteria.map((crit) => {
        if (crit.id === newCriteria.id) {
          return { ...crit, value: boolToNumber(newValue) };
        }
        return crit;
      });

      setCriteria(newCriteriaList);

      return updateAssignmentScore(newCriteria.id, {
        value: newValue
      });
    }
  };

  const handleScoreChange = (activeTask, value) => {
    submit(
      updateAssignmentScore(activeTask.id, { value }).then(() => {
        // create a new list object to force a re-render
        onTaskUpdate({ ...activeTask, value, alreadyRendered: true });
      })
    );
  };

  return (
    <div
      className={classnames(styles.rightMenu, {
        [styles.expanded]: isExpanded
      })}
    >
      {isExpanded && (
        <>
          <Section className={styles.sectionForHidingMenu}>
            <IconsList>
              {[
                <IconButton key={1} size="small" onClick={hideMenu}>
                  <ChevronRightRoundedIcon fontSize="large" />
                </IconButton>
              ]}
            </IconsList>
          </Section>
          <Divider />
        </>
      )}

      <Section>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.sectionHeading}
        >
          Edit image
        </Typography>
        <IconsList>
          <TooltipIconButton
            tooltipText="Rotate"
            value="rotate"
            className={styles.toggleButton}
            disabled={audioButton.isRecording}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              }
              drawButton.turnOff();
              filterButton.turnOff();
              stickersButton.turnOff();
              audioButton.turnOff();
              rotateImage();
            }}
          >
            <Icons.Rotate
              htmlColor={colors.blue3}
              className={styles.mirrored}
            />
          </TooltipIconButton>
          <TooltipToggleButton
            tooltipText="Crop"
            value="Crop"
            className={styles.toggleButton}
            style={{ backgroundColor: cropButton.active && colors.grey6 }}
            disabled={audioButton.isRecording}
            selected={cropButton.active}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              } else {
                cropButton.toggle();
              }
              drawButton.turnOff();
              filterButton.turnOff();
              stickersButton.turnOff();
              audioButton.turnOff();
              // Fixes a race condition
              setTimeout(zoom.zoomToNormal, 0);
            }}
          >
            <Icons.Crop
              htmlColor={cropButton.active ? colors.blue5 : colors.blue3}
            />
          </TooltipToggleButton>
          <TooltipToggleButton
            tooltipText="Filter"
            value="filter"
            className={styles.toggleButton}
            style={{ backgroundColor: filterButton.active && colors.grey6 }}
            disabled={audioButton.isRecording}
            selected={filterButton.active}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              }
              filterButton.toggle();
              drawButton.turnOff();
              stickersButton.turnOff();
              audioButton.turnOff();
            }}
          >
            <Icons.Exposure
              htmlColor={filterButton.active ? colors.blue5 : colors.blue3}
            />
          </TooltipToggleButton>
          <TooltipIconButton
            tooltipText="Undo"
            value="undo"
            className={styles.toggleButton}
            disabled={audioButton.isRecording || undo.isEmpty}
            onClick={undo.pop}
          >
            <UndoRoundedIcon fontSize="large" htmlColor={colors.blue3} />
          </TooltipIconButton>
        </IconsList>
      </Section>
      <Divider />
      <Section>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.sectionHeading}
        >
          Feedback
        </Typography>
        <IconsList>
          <TooltipToggleButton
            tooltipText="Draw"
            className={styles.toggleButton}
            style={{ backgroundColor: drawButton.active && colors.grey6 }}
            disabled={audioButton.isRecording}
            selected={drawButton.active}
            onClick={() => {
              if (filterButton.active) {
                // Save the applied filter before turning the draw mode on
                filterButton.apply();
              }
              if (cropButton.active) {
                cropButton.cropImage();
              }

              filterButton.turnOff();
              stickersButton.turnOff();
              audioButton.turnOff();
              drawButton.toggle();
            }}
            value="brush"
          >
            <Icons.Brush
              htmlColor={drawButton.active ? colors.blue5 : colors.blue3}
            />
          </TooltipToggleButton>
          <TooltipIconButton
            tooltipText="Text"
            className={styles.toggleButton}
            value="text"
            disabled={audioButton.isRecording}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              }
              drawButton.turnOff();
              filterButton.turnOff();
              stickersButton.turnOff();
              audioButton.turnOff();
              textButton.apply();
            }}
          >
            <Icons.Text htmlColor={colors.blue3} />
          </TooltipIconButton>
          <TooltipToggleButton
            tooltipText="Sticker"
            className={styles.toggleButton}
            style={{ backgroundColor: stickersButton.active && colors.grey6 }}
            value="sticker"
            selected={stickersButton.active}
            disabled={audioButton.isRecording}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              }
              drawButton.turnOff();
              filterButton.turnOff();
              audioButton.turnOff();
              stickersButton.toggle();
            }}
          >
            <Icons.Smile
              htmlColor={stickersButton.active ? colors.blue5 : colors.blue3}
            />
          </TooltipToggleButton>
          <TooltipToggleButton
            tooltipText="Record Audio"
            className={styles.toggleButton}
            style={{ backgroundColor: audioButton.active && colors.grey6 }}
            value="record"
            selected={audioButton.active}
            onClick={() => {
              if (cropButton.active) {
                cropButton.cropImage();
              }
              drawButton.turnOff();
              stickersButton.turnOff();
              audioButton.toggle();
            }}
          >
            <Icons.Audio
              htmlColor={audioButton.active ? colors.blue5 : colors.blue3}
            />
          </TooltipToggleButton>
        </IconsList>
      </Section>
      <Divider />
      {!_.isEmpty(_.get(activeTask, 'criteria', [])) && (
        <>
          <Section>
            <Typography
              variant="S-TEXT-1"
              color={colors.grey3}
              className={styles.sectionHeading}
            >
              Criteria
            </Typography>
            <MenuList disablePadding>
              {(criteria || []).map((criteria, index) => (
                <MenuItem
                  component="div"
                  key={index}
                  className={styles.listItem}
                  disabled={submitting}
                  onClick={() => handleCriteriaClick(criteria)}
                >
                  <CheckCircleIcon
                    htmlColor={criteria.value ? colors.green3 : colors.grey4}
                    style={{ fontSize: 25 }}
                  />
                  <Typography
                    variant="B-Text-1"
                    color={colors.grey2}
                    className={styles.criteriaText}
                  >
                    {criteria.name}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Section>
          <Divider />
        </>
      )}
      <Section className={styles.noPaddingRight} key={activeTask.id}>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.sectionHeading}
        >
          Score
        </Typography>
        {activeTask.value_type === AssignmentTaskValueTypes.CHECKBOX && (
          <MenuList disablePadding>
            <MenuItem
              className={styles.listItem}
              onClick={() => handleScoreChange(activeTask, 0)}
              selected={activeTask.value === 0}
              style={{
                backgroundColor: activeTask.value === 0 && colors.grey6
              }}
            >
              <Icons.Incorrect
                fontSize="small"
                htmlColor={
                  activeTask.value === 0 ? colors.green3 : colors.grey4
                }
              />
              <Typography
                noWrap
                variant="B-Text-1"
                color={colors.grey2}
                className={styles.criteriaText}
              >
                Incorrect
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => handleScoreChange(activeTask, 1)}
              className={styles.listItem}
              selected={activeTask.value === 1}
              style={{
                backgroundColor: activeTask.value === 1 && colors.grey6
              }}
            >
              <Icons.Correct
                fontSize="small"
                htmlColor={
                  activeTask.value === 1 ? colors.green3 : colors.grey4
                }
              />
              <Typography
                noWrap
                variant="B-Text-1"
                color={colors.grey2}
                className={styles.criteriaText}
              >
                Correct
              </Typography>
            </MenuItem>
          </MenuList>
        )}
        {activeTask.value_type === AssignmentTaskValueTypes.CHECK_GRADE && (
          <MenuList disablePadding>
            <MenuItem
              className={styles.listItem}
              onClick={() => handleScoreChange(activeTask, -1)}
              selected={activeTask.value === -1}
              style={{
                backgroundColor: activeTask.value === -1 && colors.grey6
              }}
            >
              <Icons.CheckMinus
                fontSize="small"
                htmlColor={
                  activeTask.value === -1 ? colors.blue3 : colors.grey4
                }
              />
              <Typography
                noWrap
                variant="B-Text-1"
                color={colors.grey2}
                className={styles.criteriaText}
              >
                Check Minus
              </Typography>
            </MenuItem>
            <MenuItem
              className={styles.listItem}
              onClick={() => handleScoreChange(activeTask, 0)}
              selected={activeTask.value === 0}
              style={{
                backgroundColor: activeTask.value === 0 && colors.grey6
              }}
            >
              <Icons.Check
                fontSize="small"
                htmlColor={activeTask.value === 0 ? colors.blue3 : colors.grey4}
              />
              <Typography
                noWrap
                variant="B-Text-1"
                color={colors.grey2}
                className={styles.criteriaText}
              >
                Check
              </Typography>
            </MenuItem>
            <MenuItem
              className={styles.listItem}
              onClick={() => handleScoreChange(activeTask, 1)}
              selected={activeTask.value === 1}
              style={{
                backgroundColor: activeTask.value === 1 && colors.grey6
              }}
            >
              <Icons.CheckPlus
                fontSize="small"
                htmlColor={activeTask.value === 1 ? colors.blue3 : colors.grey4}
              />
              <Typography
                noWrap
                variant="B-Text-1"
                color={colors.grey2}
                className={styles.criteriaText}
              >
                Check Plus
              </Typography>
            </MenuItem>
          </MenuList>
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.NUMBER && (
          <DebouncedField
            variant="underlined"
            type="number"
            placeholder="1-100"
            component={InputField}
            defaultValue={activeTask.value}
            className={styles.scoreInput}
            onChange={(value) => handleScoreChange(activeTask, value)}
          />
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.CUSTOM && (
          <DebouncedField
            variant="underlined"
            type="number"
            placeholder={`1-${activeTask.max_value}`}
            component={InputField}
            defaultValue={activeTask.value}
            className={styles.scoreInput}
            onChange={(value) => handleScoreChange(activeTask, value)}
          />
        )}

        {activeTask.value_type ===
          AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR && (
          <IconsList>
            {AssignmentTaskValueTypeValueRange[activeTask.value_type].map(
              (num) => {
                const active = activeTask.value === num;

                return (
                  <div
                    key={num}
                    onClick={() => handleScoreChange(activeTask, num)}
                    className={styles.rubricTile}
                    style={{
                      backgroundColor: active ? colors.green4 : colors.grey6
                    }}
                  >
                    <Typography
                      variant="H-TEXT-3"
                      color={active ? colors.blue1 : colors.grey3}
                    >
                      {num}
                    </Typography>
                  </div>
                );
              }
            )}
          </IconsList>
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.RUBRIC_A_TO_F && (
          <IconsList>
            {AssignmentTaskValueTypeValueRange[activeTask.value_type].map(
              (grade) => {
                const valueForGrade = getRubricAFValueByRawValue({
                  rawValue: grade
                });

                const active = activeTask.value === valueForGrade;

                return (
                  <div
                    key={valueForGrade}
                    onClick={() => handleScoreChange(activeTask, valueForGrade)}
                    className={styles.rubricTile}
                    style={{
                      backgroundColor: active ? colors.green4 : colors.grey6
                    }}
                  >
                    <Typography
                      variant="H-TEXT-3"
                      color={active ? colors.blue1 : colors.grey3}
                    >
                      {grade}
                    </Typography>
                  </div>
                );
              }
            )}
          </IconsList>
        )}
      </Section>
      <Divider />
      <Section>
        <Typography
          variant="S-TEXT-1"
          color={colors.grey3}
          className={styles.sectionHeading}
        >
          Status
        </Typography>
        <div className={styles.statusButtons}>
          <Button
            fullWidth
            color="pink"
            variant="textAndIcon"
            startIcon={<RotateRightIcon />}
            onClick={() => {
              onClickAssignmentStatus(
                AssignmentStatusOptions.REVISE,
                advanceOnStatusChange
              );
            }}
          >
            Revise
          </Button>

          <Button
            fullWidth
            color="green"
            variant="textAndIcon"
            startIcon={<DoneIcon />}
            onClick={() => {
              onClickAssignmentStatus(
                AssignmentStatusOptions.COMPLETE,
                advanceOnStatusChange
              );
            }}
          >
            Complete
          </Button>
        </div>
      </Section>
      <Divider />
      {(submitting || isLoading) && <LinearProgress variant="indeterminate" />}
      <Section className={styles.advanceSwitch}>
        <Typography variant="S-TEXT-2" color={colors.grey1}>
          Advance on
          <br />
          status change
        </Typography>
        <Switch
          checked={advanceOnStatusChange}
          onChange={(e) => setAdvanceOnStatusChange(e.target.checked)}
        />
      </Section>
      <Divider />
    </div>
  );
};

const ResponsiveRightMenu = ({
  activeTask,
  activeWork,
  rotateImage,
  cropButton,
  filterButton,
  drawButton,
  textButton,
  stickersButton,
  audioButton,
  onTaskUpdate,
  onClickAssignmentStatus,
  zoom,
  expandMenu,
  undo,
  isLoading
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [criteriaMenuAnchor, setCriteriaMenuAnchor] = useState();
  const [criteria, setCriteria] = useCachedCriteria(activeTask);

  const handleCriteriaMenuOpen = (event) => {
    setCriteriaMenuAnchor(event.currentTarget);
  };

  const handleCriteriaMenuClose = () => {
    setCriteriaMenuAnchor();
  };

  const activeWorkId = activeWork && activeWork.id;
  const prevWorkId = usePrevious(activeWorkId);
  useEffect(() => {
    // Deactivate all tools when the image is changed
    if (prevWorkId && activeWorkId !== prevWorkId) {
      cropButton.turnOff();
      filterButton.remove();
      filterButton.turnOff();
      drawButton.turnOff();
      audioButton.turnOff();
      stickersButton.turnOff();
      undo.clear();
      // Fixes a race condition
      setTimeout(zoom.zoomToNormal, 0);
    }
  }, [
    activeWorkId,
    prevWorkId,
    cropButton,
    filterButton,
    drawButton,
    audioButton,
    stickersButton,
    undo,
    zoom
  ]);

  const submit = (promise) => {
    setSubmitting(true);
    promise.then(() => setSubmitting(false)).catch(() => setSubmitting(false));
  };

  const handleCriteriaClick = (newCriteria) => {
    // This check is for legacy reasons.
    // Old value_types can be "rubric"/"check_grade"/"checkbox". We want to submit only if it's "checkbox"
    if (newCriteria.value_type === AssignmentTaskValueTypes.CHECKBOX) {
      const newValue = !numberToBool(newCriteria.value);
      // `newCriteria` type objects share the same id with `Task` type objects.
      const newCriteriaList = criteria.map((crit) => {
        if (crit.id === newCriteria.id) {
          return { ...crit, value: boolToNumber(newValue) };
        }
        return crit;
      });

      setCriteria(newCriteriaList);

      return updateAssignmentScore(newCriteria.id, {
        value: newValue
      });
    }
  };

  const handleScoreChange = (activeTask, value) => {
    submit(
      updateAssignmentScore(activeTask.id, { value }).then(() => {
        // create a new list object to force a re-render
        onTaskUpdate({ ...activeTask, value, alreadyRendered: true });
      })
    );
  };

  return (
    <div className={styles.responsiveRightMenu}>
      <div
        className={classnames(
          styles.iconContainer,
          styles.rightMenuExpandButton
        )}
      >
        <IconButton size="small" onClick={expandMenu}>
          <ChevronLeftRoundedIcon fontSize="large" />
        </IconButton>
      </div>
      <Divider />
      <div className={styles.iconContainer}>
        <IconButton
          value="rotate"
          className={styles.toggleButton}
          disabled={audioButton.isRecording}
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            }
            drawButton.turnOff();
            filterButton.turnOff();
            stickersButton.turnOff();
            audioButton.turnOff();
            rotateImage();
          }}
        >
          <Icons.Rotate htmlColor={colors.blue3} className={styles.mirrored} />
        </IconButton>
      </div>
      <div
        className={classnames(styles.iconContainer, {
          [styles.selectedButton]: cropButton.active
        })}
      >
        <ToggleButton
          value="crop"
          className={styles.toggleButton}
          disabled={audioButton.isRecording}
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            } else {
              cropButton.toggle();
            }
            drawButton.turnOff();
            filterButton.turnOff();
            stickersButton.turnOff();
            audioButton.turnOff();
            // Fixes a race condition
            setTimeout(zoom.zoomToNormal, 0);
          }}
        >
          <Icons.Crop
            htmlColor={cropButton.active ? colors.blue5 : colors.blue3}
          />
        </ToggleButton>
      </div>
      <div
        className={classnames(styles.iconContainer, {
          [styles.selectedButton]: filterButton.active
        })}
      >
        <ToggleButton
          value="filter"
          className={styles.toggleButton}
          disabled={audioButton.isRecording}
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            }
            filterButton.toggle();
            drawButton.turnOff();
            stickersButton.turnOff();
            audioButton.turnOff();
          }}
        >
          <Icons.Exposure
            htmlColor={filterButton.active ? colors.blue5 : colors.blue3}
          />
        </ToggleButton>
      </div>
      <div className={styles.iconContainer}>
        <IconButton
          value="undo"
          className={styles.toggleButton}
          disabled={audioButton.isRecording || undo.isEmpty}
          onClick={undo.pop}
        >
          <UndoRoundedIcon fontSize="large" htmlColor={colors.blue3} />
        </IconButton>
      </div>
      <Divider />
      <div
        className={classnames(styles.iconContainer, {
          [styles.selectedButton]: drawButton.active
        })}
      >
        <ToggleButton
          className={styles.toggleButton}
          disabled={audioButton.isRecording}
          onClick={() => {
            if (filterButton.active) {
              // Save the applied filter before turning the draw mode on
              filterButton.apply();
            }
            if (cropButton.active) {
              cropButton.cropImage();
            }

            filterButton.turnOff();
            stickersButton.turnOff();
            audioButton.turnOff();
            drawButton.toggle();
          }}
          value="brush"
        >
          <Icons.Brush
            htmlColor={drawButton.active ? colors.blue5 : colors.blue3}
          />
        </ToggleButton>
      </div>
      <div className={styles.iconContainer}>
        <IconButton
          className={styles.toggleButton}
          value="text"
          disabled={audioButton.isRecording}
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            }
            drawButton.turnOff();
            filterButton.turnOff();
            stickersButton.turnOff();
            audioButton.turnOff();
            textButton.apply();
          }}
        >
          <Icons.Text htmlColor={colors.blue3} />
        </IconButton>
      </div>
      <div
        className={classnames(styles.iconContainer, {
          [styles.selectedButton]: stickersButton.active
        })}
      >
        <ToggleButton
          className={styles.toggleButton}
          value="sticker"
          disabled={audioButton.isRecording}
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            }
            drawButton.turnOff();
            filterButton.turnOff();
            audioButton.turnOff();
            stickersButton.toggle();
          }}
        >
          <Icons.Smile
            htmlColor={stickersButton.active ? colors.blue5 : colors.blue3}
          />
        </ToggleButton>
      </div>
      <div
        className={classnames(styles.iconContainer, {
          [styles.selectedButton]: audioButton.active
        })}
      >
        <ToggleButton
          className={styles.toggleButton}
          value="record"
          onClick={() => {
            if (cropButton.active) {
              cropButton.cropImage();
            }
            drawButton.turnOff();
            stickersButton.turnOff();
            audioButton.toggle();
          }}
        >
          <Icons.Audio
            htmlColor={audioButton.active ? colors.blue5 : colors.blue3}
          />
        </ToggleButton>
      </div>
      <Divider />

      {!_.isEmpty(activeTask.criteria) && (
        <>
          <div
            className={classnames(styles.iconContainer, styles.criteriaButton, {
              [styles.selectedButton]: Boolean(criteriaMenuAnchor)
            })}
          >
            <ToggleButton
              value="criteria"
              className={styles.toggleButton}
              onClick={handleCriteriaMenuOpen}
            >
              <CheckCircleIcon
                htmlColor={
                  _.isEmpty(activeTask.criteria.filter((x) => !x.value))
                    ? colors.green3
                    : colors.grey4
                }
                style={{ fontSize: 25 }}
              />
            </ToggleButton>
            <Popover
              id="criteria-popover"
              anchorEl={criteriaMenuAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right'
              }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(criteriaMenuAnchor)}
              onClose={handleCriteriaMenuClose}
            >
              <Section>
                <Typography
                  variant="S-TEXT-1"
                  color={colors.grey3}
                  className={styles.sectionHeading}
                >
                  Criteria
                </Typography>
                <MenuList disablePadding>
                  {(criteria || []).map((criteria, index) => (
                    <MenuItem
                      component="div"
                      key={index}
                      className={styles.listItem}
                      disabled={submitting}
                      onClick={() => handleCriteriaClick(criteria)}
                    >
                      <CheckCircleIcon
                        htmlColor={
                          criteria.value ? colors.green3 : colors.grey4
                        }
                        style={{ fontSize: 25 }}
                      />
                      <Typography
                        variant="B-Text-1"
                        color={colors.grey2}
                        className={styles.criteriaText}
                      >
                        {criteria.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </Section>
            </Popover>
          </div>
          <Divider />
        </>
      )}

      <div key={activeTask.id}>
        {activeTask.value_type === AssignmentTaskValueTypes.CHECKBOX && (
          <>
            <div className={styles.iconContainer}>
              <IconButton
                className={styles.listItem}
                onClick={() => handleScoreChange(activeTask, 0)}
                style={{
                  backgroundColor: activeTask.value === 0 && colors.grey6
                }}
              >
                <Icons.Incorrect
                  fontSize="small"
                  htmlColor={
                    activeTask.value === 0 ? colors.green3 : colors.grey4
                  }
                />
              </IconButton>
            </div>
            <div className={styles.iconContainer}>
              <IconButton
                onClick={() => handleScoreChange(activeTask, 1)}
                className={styles.listItem}
                selected={activeTask.value === 1}
                style={{
                  backgroundColor: activeTask.value === 1 && colors.grey6
                }}
              >
                <Icons.Correct
                  fontSize="small"
                  htmlColor={
                    activeTask.value === 1 ? colors.green3 : colors.grey4
                  }
                />
              </IconButton>
            </div>
          </>
        )}
        {activeTask.value_type === AssignmentTaskValueTypes.CHECK_GRADE && (
          <>
            <div
              className={classnames(styles.iconContainer, {
                [styles.selectedButton]: activeTask.value === -1
              })}
            >
              <IconButton
                className={styles.listItem}
                onClick={() => handleScoreChange(activeTask, -1)}
              >
                <Icons.CheckMinus
                  fontSize="small"
                  htmlColor={
                    activeTask.value === -1 ? colors.blue3 : colors.grey4
                  }
                />
              </IconButton>
            </div>
            <div
              className={classnames(styles.iconContainer, {
                [styles.selectedButton]: activeTask.value === 0
              })}
            >
              <IconButton
                className={styles.listItem}
                onClick={() => handleScoreChange(activeTask, 0)}
              >
                <Icons.Check
                  fontSize="small"
                  htmlColor={
                    activeTask.value === 0 ? colors.blue3 : colors.grey4
                  }
                />
              </IconButton>
            </div>
            <div
              className={classnames(styles.iconContainer, {
                [styles.selectedButton]: activeTask.value === 1
              })}
            >
              <IconButton
                className={styles.listItem}
                onClick={() => handleScoreChange(activeTask, 1)}
              >
                <Icons.CheckPlus
                  fontSize="small"
                  htmlColor={
                    activeTask.value === 1 ? colors.blue3 : colors.grey4
                  }
                />
              </IconButton>
            </div>
          </>
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.NUMBER && (
          <DebouncedField
            variant="underlined"
            type="number"
            placeholder="1-100"
            component={InputField}
            defaultValue={activeTask.value}
            className={styles.responsiveScoreInput}
            onChange={(value) => handleScoreChange(activeTask, value)}
          />
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.CUSTOM && (
          <DebouncedField
            variant="underlined"
            type="number"
            placeholder={`1-${activeTask.max_value}`}
            component={InputField}
            defaultValue={activeTask.value}
            className={styles.scoreInput}
            onChange={(value) => handleScoreChange(activeTask, value)}
          />
        )}

        {activeTask.value_type ===
          AssignmentTaskValueTypes.RUBRIC_ZERO_FOUR && (
          <Grid container direction="column" alignItems="center">
            {AssignmentTaskValueTypeValueRange[activeTask.value_type].map(
              (num) => {
                const active = activeTask.value === num;

                return (
                  <Grid
                    item
                    key={num}
                    onClick={() => handleScoreChange(activeTask, num)}
                    className={styles.responsiveRubricTile}
                    style={{
                      backgroundColor: active ? colors.green4 : colors.grey6
                    }}
                  >
                    <Typography
                      variant="H-TEXT-3"
                      color={active ? colors.blue1 : colors.grey3}
                    >
                      {num}
                    </Typography>
                  </Grid>
                );
              }
            )}
          </Grid>
        )}

        {activeTask.value_type === AssignmentTaskValueTypes.RUBRIC_A_TO_F && (
          <Grid container direction="column" alignItems="center">
            {AssignmentTaskValueTypeValueRange[activeTask.value_type].map(
              (grade) => {
                const valueForGrade = getRubricAFValueByRawValue({
                  rawValue: grade
                });

                const active = activeTask.value === valueForGrade;

                return (
                  <Grid
                    item
                    key={valueForGrade}
                    onClick={() => handleScoreChange(activeTask, valueForGrade)}
                    className={styles.responsiveRubricTile}
                    style={{
                      backgroundColor: active ? colors.green4 : colors.grey6
                    }}
                  >
                    <Typography
                      variant="H-TEXT-3"
                      color={active ? colors.blue1 : colors.grey3}
                    >
                      {grade}
                    </Typography>
                  </Grid>
                );
              }
            )}
          </Grid>
        )}
      </div>

      <Divider />
      <Button
        className={styles.responsiveStatusButton}
        fullWidth
        color="pink"
        classes={{ startIcon: styles.responsiveStatusButtonIcon }}
        startIcon={<Icons.Revise />}
        onClick={() =>
          onClickAssignmentStatus(AssignmentStatusOptions.REVISE, true)
        }
      />
      {(submitting || isLoading) && <LinearProgress variant="indeterminate" />}
      <Button
        className={styles.responsiveStatusButton}
        classes={{ startIcon: styles.responsiveStatusButtonIcon }}
        fullWidth
        color="green"
        startIcon={<CheckCircleOutlineRoundedIcon />}
        onClick={() =>
          onClickAssignmentStatus(AssignmentStatusOptions.COMPLETE, true)
        }
      />
    </div>
  );
};

const RightMenu = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <ExpandedRightMenu
        isExpanded={isExpanded}
        hideMenu={() => setIsExpanded(false)}
        {...props}
      />
      <ResponsiveRightMenu expandMenu={() => setIsExpanded(true)} {...props} />
    </>
  );
};

export default RightMenu;
