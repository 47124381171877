import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';

import { history } from 'config/routes';
import {
  TEACHERS_ASSIGNMENTS_FEEDBACK_URL,
  TEACHERS_ASSIGNMENTS_RESULTS_URL
} from 'config/urls';
import _ from 'lodash';
import moment from 'moment';

import { useNavbar } from 'pages/Teachers/shared';
import { uploadImage } from 'utils/files';
import { notifyErrors } from 'utils/notifications';
import { parseParams, reverse, stringifyParams } from 'utils/urls';

import SectionSelect from './components/SectionSelect';
import StudentSelect from './components/StudentSelect';

import { fetchAssignmentSections, uploadAssignmentFeedback } from './sdk';

// Finds the object in the list and returns it, or returns undefined.
export const getObjectFromList = (objList, id) => {
  return _.find(objList, { id: parseInt(id) });
};

export const getObjectFromListOrFirst = (objList, id) => {
  return getObjectFromList(objList, id) || _.first(objList);
};

const getPreviousTask = (tasks, activeTask) => {
  const prevTask = _.last(
    _.dropRight(
      _.dropRightWhile(tasks, (task) => task.id !== activeTask.id),
      1
    )
  );
  if (!prevTask) {
    return _.last(tasks);
  }
  return prevTask;
};

const getNextTask = (tasks, activeTask) => {
  const nextTask = _.head(
    _.drop(
      _.dropWhile(tasks, (task) => task.id !== activeTask.id),
      1
    )
  );
  if (!nextTask) {
    return _.head(tasks);
  }
  return nextTask;
};

/**
 * Returns:
 *   [`sections`: Array<Section>, `fetchSections`: (trackerId: number) => Promise]
 */
export const useSections = () => {
  const [sections, setSections] = useState([]);

  const fetchSections = (trackerId) => {
    return fetchAssignmentSections(trackerId).then(
      ({ success, data, errors }) => {
        if (success) {
          setSections(data);
          return data;
        } else {
          notifyErrors(errors);
        }
      }
    );
  };

  const [response, setResponse] = useState([sections, fetchSections]);

  useEffect(() => {
    setResponse([sections, fetchSections]);
  }, [sections]);

  return response;
};

export const useCelebratoryDialogActions = ({ trackerId, sectionId }) => {
  const [isCelebratoryDialogVisible, setIsCelebratoryDialogVisible] =
    useState(false);
  const [closestAssignment, setClosestAssignment] = useState(null);

  const goToAssignmentResults = () => {
    history.push({
      pathname: reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
        trackerId: trackerId
      }),
      search: stringifyParams({
        section: sectionId
      })
    });
  };

  const goToNextAssignment = () =>
    new Promise((resolve, reject) => {
      const { tracker, section, student } = closestAssignment;

      if (tracker) {
        history.push({
          pathname: reverse(TEACHERS_ASSIGNMENTS_FEEDBACK_URL, {
            trackerId: tracker
          }),
          search: stringifyParams({
            student: student,
            section: section
          })
        });
        setIsCelebratoryDialogVisible(false);

        resolve({ sectionId: section, studentId: student });
      } else {
        reject();
      }
    });

  return [
    isCelebratoryDialogVisible,
    setIsCelebratoryDialogVisible,
    closestAssignment,
    setClosestAssignment,
    goToAssignmentResults,
    goToNextAssignment
  ];
};

export const useNavigation = ({
  assignmentName,
  sections,
  selectedSection,
  onSectionSelect,
  students,
  selectedStudent,
  onStudentSelect,
  ...rest
}) => {
  const navBar = useNavbar({
    title: assignmentName,
    ...rest
  });

  useEffect(() => {
    navBar.navigation.setLeftSubheader(
      <SectionSelect
        sections={sections}
        selectedSection={selectedSection}
        setSelectedSection={onSectionSelect}
      />
    );
  }, [navBar.navigation, sections, selectedSection, onSectionSelect]);
  useEffect(() => {
    navBar.navigation.setRightSubheader(
      <StudentSelect
        students={students}
        selectedStudent={selectedStudent}
        setSelectedStudent={onStudentSelect}
      />
    );
  }, [navBar.navigation, students, selectedStudent, onStudentSelect]);
};

export const usePreviousAndNextTasks = ({ tasks, task }) => {
  const [previousTask, setPreviousTask] = useState();
  const [nextTask, setNextTask] = useState();

  useEffect(() => {
    if (task) {
      setPreviousTask(getPreviousTask(tasks, task));
      setNextTask(getNextTask(tasks, task));
    }
  }, [task, tasks]);

  return [previousTask, nextTask];
};

export const changePageParams = ({
  selectedSectionId,
  selectedStudentId,
  activeTaskId,
  workItemId
}) => {
  const params = parseParams(history.location.search);
  if (selectedSectionId) {
    params.section = selectedSectionId;
  }
  if (_.isNull(selectedSectionId)) {
    delete params.section;
  }

  if (selectedStudentId) {
    params.student = selectedStudentId;
  }

  if (_.isNull(selectedStudentId)) {
    delete params.student;
  }

  if (activeTaskId) {
    params.task = activeTaskId;
  }

  if (_.isNull(activeTaskId)) {
    delete params.task;
  }

  if (workItemId) {
    params.work = workItemId;
  }

  if (_.isNull(workItemId)) {
    delete params.work;
  }

  history.replace({
    search: stringifyParams(params)
  });
};

export const loadImageFromSrc = (src) =>
  new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.crossOrigin = 'Anonymous';
    image.onerror = (error) => {
      notifyErrors(['There was an error in loading some images']);
      Sentry.captureException(
        new Error('There was an error in loading the next image'),
        {
          extra: {
            error,
            src: image.src
          }
        }
      );
    };

    if (!_.startsWith(src, 'blob')) {
      // When we call s3 for an image and we pass "crossOrigin=Anonymous"
      // we get back a CORS error and not the image. That usually happens if the image was previously cached
      // by the browser (only in Chrome) and it was cached without the "crossOrigin = Anonymous"
      // To fix this - we have to send another request with a random GET parameter.
      // The key and value of the GET parameter doesn't matter. We just use it to tell Chrome to NOT
      // get the image from it's cache this time
      //https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
      image.src = `${src}?dont-cache=true`;
    }
  });

const saveImage = async (blob, activeWork, feedbackTexts) => {
  const feedbackFileId = await uploadImage({
    blob,
    name: 'feedback'
  });

  const data = await uploadAssignmentFeedback({
    feedback_file_id: feedbackFileId,
    original_work: activeWork.original_id || activeWork.id,
    work_id: activeWork.id,
    feedback_texts: feedbackTexts
  });

  return data;
};

const uploadImageBlob = async (blob, activeWork, feedbackTexts) => {
  const res = await saveImage(blob, activeWork, feedbackTexts);

  return res;
};

export const handleTaskStatusChange = ({
  autoAdvance,
  activeTask,
  activeWork,
  fastContext,
  setActiveWork,
  addTaskWork,
  setTaskWork,
  canvasHasNewObjects,
  blob,
  feedbackTexts
}) => {
  if (!autoAdvance && canvasHasNewObjects) {
    // <optimisation> This optimises for people with slow internet connection
    const imageUrl = window.URL.createObjectURL(blob);
    loadImageFromSrc(imageUrl).then((image) =>
      fastContext.drawInitialImageObject(image)
    );

    const newWork = {
      is_revision: false,
      is_feedback: true,
      work_url: imageUrl,
      s3_work_url: imageUrl,
      created_at: moment()
    };

    addTaskWork({ activeTask, work: newWork, alreadyRendered: true });
    setActiveWork(newWork);
    // </optimisation>

    uploadImageBlob(blob, activeWork, feedbackTexts).then((newWorkObject) => {
      setActiveWork({
        ...newWorkObject,
        work_url: imageUrl,
        s3_work_url: imageUrl
      });
      // This is the copy of "activeTask" before the dummy "work" was added, so it contains only the previous "work" list
      setTaskWork(activeTask, [newWorkObject, ...activeTask.work]);
    });
  }
  if (autoAdvance && canvasHasNewObjects) {
    uploadImageBlob(blob, activeWork, feedbackTexts);
  }
};

export const useEnsurePageParams = ({
  urlParams,
  selectedSectionId,
  selectedStudentId,
  activeTaskId,
  workItemId
}) => {
  useEffect(() => {
    if (selectedSectionId && urlParams.section !== selectedSectionId) {
      changePageParams({ selectedSectionId });
    }
  }, [selectedSectionId, urlParams.section]);

  useEffect(() => {
    if (selectedStudentId && urlParams.student !== selectedStudentId) {
      changePageParams({ selectedStudentId });
    }
  }, [selectedStudentId, urlParams.student]);

  useEffect(() => {
    if (activeTaskId && urlParams.task !== activeTaskId) {
      changePageParams({ activeTaskId });
    }
  }, [activeTaskId, urlParams.task]);

  useEffect(() => {
    if (workItemId && urlParams.work !== workItemId) {
      changePageParams({ workItemId });
    }
  }, [workItemId, urlParams.work]);
};
